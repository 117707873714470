.card{
    gap: 2.5rem;
    background: linear-gradient(60deg, #845ec2, #d65db1, #ff6f91, #ff9671, #ffc75f);
}

.card2{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.move-up{
    background-color: crimson;
    transition: all 1s;
    transform : translateY(0px);
}
.move-up:hover{
    background-color: white;
    transition: all 1s;
    transform : translateY(-15px);
}

.dashed-2 {
    border: none;
    height: 5px;
    width: 100%;
    background: rgb(255, 255, 255);
    background: repeating-linear-gradient(90deg,black,black 6px,transparent 6px,transparent 12px);
    top:300px
  }

.landingVideo {
    object-fit: fill;
    height: 85vh;
}

.bannerMod{
    object-fit: contain;
    /* width: auto; */
    background-repeat: no-repeat;
    /* background-size: cover; */
    /* max-width: 100%;
    max-height: 200; */
    width: 200;
    height: 100;

}

.navTitle {
    font-size: 45px;
}


.fancyButton1 {
    border: none;
    background-color: white;
    box-shadow: 0 1px 9px rgba(0,0,0,0.1),
                0 0 0 100px #1cb inset;
    transform: skewX(-18deg);
    transition: none;
    height:75px;
    width:250px;
    font-size: 30px;
    opacity: .8;
}
  
.fancyButton1:hover {
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0 2px white inset;
    background-color: #1cb;
    transition: all 0.2s;
    opacity: 1;
    
}
  
.fancyButton1:active {
    color: #1cb;
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0px 200px white inset;
    transition: none;
}

.cardFlash {
    border: none;
    background-color: white;
    box-shadow: 0 1px 9px rgba(0,0,0,0.1),
                0 0 0 100px orange inset;
    transform: skewX(-18deg);
    transition: none;
    height:50px;
    width:250px;
    font-size:25px;
}
  
.cardFlash:hover {
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0 2px white inset;
    background-color: orange;
    transition: all 0.2s;
}
  
/* .cardFlash:active {
    color: orange;
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0px 200px white inset;
    transition: none;
} */

.cardFlash1 {
    border: none;
    background-color: white;
    box-shadow: 0 1px 9px rgba(0,0,0,0.1),
                0 0 0 100px #1cb inset;
    transform: skewX(-18deg);
    transition: none;
    height:50px;
    width:250px;
    font-size:20px;
}
  
.cardFlash1:hover {
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0 2px white inset;
    background-color: #1cb;
    transition: all 0.2s;
}
  
/* .cardFlash1:active {
    color: #1cb;
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0px 200px white inset;
    transition: none;
} */




.fancyButton3 {
    border: none;
    background-color: white;
    box-shadow: 0 1px 9px rgba(0,0,0,0.1),
                0 0 0 100px crimson inset;
    transform: skewX(-18deg);
    transition: none;
}
  
.fancyButton3:hover {
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0 2px white inset;
    background-color: crimson;
    transition: all 0.2s;
}
  
.fancyButton3:active {
    color: crimson;
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0px 200px white inset;
    transition: none;
}

.fancyButton4 {
    border: none;
    background-color: white;
    box-shadow: 0 1px 9px rgba(0,0,0,0.1),
                0 0 0 100px crimson inset;
    transform: skewX(-18deg);
    transition: none;
    height:75px;
    width:250px;
    font-size: 30px;
    opacity: .8;
}
  
.fancyButton4:hover {
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0 2px white inset;
    background-color: crimson;
    transition: all 0.2s;
    opacity: 1;
}
  
.fancyButton4:active {
    color: crimson;
    box-shadow: 0 3px 20px rgba(0,0,0,0.3),
                0 0 0px 200px white inset;
    transition: none;
}

.roadMapTitle {
    font-family:'Bungee';
    font-size:45px;
    color:'black';
    margin:0
}

.timeCardInner {
    height:450px;
    width:40vw;
    border-radius:10px;
    margin-top:50px;
    margin-bottom:50px;
    margin-left:50px;
    display:'flex';
    flex-direction:'column';
    padding:30px;
    background-color: crimson;
    align-items: center;
    justify-content: center;
}

.timeCardTitle {
    font-family:'Bungee';
    font-size:30px;
}

.timeCardSummary {
    font-size:14px;
    font-weight: bold;
    text-align:left;
    margin-bottom:  20px;
    
    /* color: white; */
}
.timeCardSummarConatainer {
    height:150px
}

.timeCardImage {
    height:100px;
    width:100px;
    padding-top: 20px;
}

.timelineOuter {
    height: 810px;
}

.appBar {
    height: 17vh;
    max-height: 150px;
}

.skateBanner {
    height:140px;
    width:70%;
    max-width: 1000px;
}

.timeCardHeader {
    flex-direction: column;
    height:40%;
}

.landingOverlay {
    height: 100vh;
    width: 100%;
}

@media only screen and (max-width: 1300px) {
    .timeCardHeader {
        flex-direction: row;
        height:30%;
    }
    .timeCardSummarConatainer{
        height:200px
    }
    .timeCardImage {
        margin-right: 20px;
        padding: 0px;
    }
}

@media only screen and (max-width: 1050px) {
    .timeCardSummary {
        font-size:13px;
    }
}

@media only screen and (max-width: 900px) {
    .skateBanner {
        height:140px;
        width:90%;
    }
    .appBar {
        height: 12vh;
        
    }
    .landingVideo {
        object-fit: fill;
        height: 88vh;
    }
    .dashed-2 {
        top:225px
    }
    .fancyButton1 {
        height:50px;
        width:150px;
        font-size: 15px;
    }
    .fancyButton4 {
        height:50px;
        width:150px;
        font-size: 15px;
    }

    .cardFlash {
        height:30px;
        width:40%;
        font-size:15px;
    }

    .cardFlash1 {
        height:30px;
        width:40%;
        font-size:15px;
        justify-content: center;
        align-items: center;
        align-self: center;
    }

    .roadMapTitle {
        font-size:40px;
    }

    .timeCardInner {
        height:400px;
        width:80vw;
        border-radius:10px;
        margin-top:20px;
        margin-bottom:50px;
        margin-left:20px;
        display:'flex';
        flex-direction:'column';
        align-items: center;
        justify-content: center;
        padding:20px;
    }

    .timeCardTitle{
        font-size: 22px;
    }

    .timeCardSummary {
        font-size: 15px;
    }

    .timeCardImage {
        height: 90px;
        width: 90px;
    }

    .timelineOuter {
        height: 700px;
    }
    .navTitle {
        font-size: 40px;
    }
  }



  @media only screen and (max-width: 500px) {

    .landingOverlay {
        height: 82vh;
    }
    .landingVideo {
        object-fit: fill;
        height: 70vh;
    }
    .skateBanner {
        height:140px;
        width:90%;
    }
    .navTitle {
        font-size: 25px;
    }
    .fancyButton1 {
        height:50px;
        width:150px;
        font-size: 15px;
    }
    .fancyButton4 {
        height:50px;
        width:150px;
        font-size: 15px;
    }

    .cardFlash {
        height:30px;
        width:90%;
        font-size:15px;
    }

    .cardFlash1 {
        height:30px;
        width:90%;
        font-size:15px;
        justify-content: center;
        align-items: center;
        align-self: center;
    }

    .roadMapTitle {
        font-size:28px;
    }

    .timeCardInner {
        height:400px;
        width:80vw;
        border-radius:10px;
        margin-top:20px;
        margin-bottom:50px;
        margin-left:20px;
        display:'flex';
        flex-direction:'column';
        align-items: center;
        justify-content: center;
        padding:20px;
    }

    .timeCardTitle{
        font-size: 20px;
    }

    .timeCardSummary {
        font-size: 12px;
    }

    .timeCardImage {
        height: 70px;
        width: 70px;
    }
  }

  @media only screen and (max-width: 450px) {
    .timeCardSummary {
        font-size:11px;
    }
}

/* .sliderTweak >  */